import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Testimonial = ({ children, patient, right }) => (
  <div className="py-12 overflow-hidden md:py-20 lg:py-24">
    <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <svg
        className={`absolute top-full ${
          right ? "right-0" : "right-full"
        } transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2 hidden md:block`}
        width="404"
        height="404"
        fill="none"
        viewBox="0 0 404 404"
      >
        <defs>
          <pattern
            id="svg-pattern-squares-1"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#svg-pattern-squares-1)" />
      </svg>

      <div className="relative">
        <blockquote className="mt-8">
          <div className="italic max-w-3xl mx-auto text-center text-base leading-9 font-normal text-gray-500">
            <p>{children}</p>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-900">
                  {patient}
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </div>
)

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      site {
        siteMetadata {
          phone
        }
      }
      file(relativePath: { eq: "whitecenter-chiropractic-outside-view.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 65) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <section>
        <Img fluid={data.file.childImageSharp.fluid} className="mx-auto" />
      </section>
      <section className="py-4 sm:py-10">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 col-gap-6 row-gap-6 items-center">
            <h1 className="col-span-2 md:col-span-1 text-2xl sm:text-3xl text-blue-400 font-medium leading-tight mb-4 sm:mb-0">
              At White Center Chiropractic it is our goal to provide
              professional health care with a personal touch
            </h1>
            <p className="col-span-2 md:col-span-1 text-base sm:text-lg text-gray-500">
              Whether you have been in an automobile accident, hurt on the job,
              or are just feeling the effects of life's everyday stress, our
              office is designed to meet your needs. We strive to create a
              relaxed and comfortable environment that makes you feel safe and
              welcome from the moment you walk through our door. If you prefer a
              natural health care option{" "}
              <a
                href={`tel:${data.site.siteMetadata.phone}`}
                className="text-blue-500 hover:underline"
              >
                call us at (206) 763-7464
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <section className="py-6 sm:py-12 bg-gray-50">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Chiropractic
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  At White Center Chiropractic we offer a variety of adjustment
                  techniques specifically tailored to your needs and
                  preferences.
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Car Accidents
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Dr. Ian Davis is a specialist in rehabilitation of the spine
                  from trauma such as that commonly suffered in auto accidents.
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Massage Therapy
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Our massage therapists are trained in a variety of techniques
                  from injury specific therapeutic massage to relaxation
                  massage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <Testimonial patient="Anjanette N., Arbor Heights">
          After experiencing pain and weakness in my lower back, I decided to
          make an appointment at White Center Chiropractic. As part of my
          initial examination, Dr. Ian Davis took x-rays and discovered that my
          discomfort was likely due to the lack of correct curvature in my
          spine. With regular adjustments, faithful walks on the treadmill in
          traction, and exercises to improve my posture, my back is greatly
          improved! I have not had any of the pain I had originally, and someone
          even asked me if I was a dancer because my sitting posture was so
          tall! Thank you, Dr. Ian Davis!
        </Testimonial>
        <Testimonial patient="Patricia P., White Center" right>
          I suffered from neck, upper back and lower back pain for years without
          knowing that I had severe curvature to my spine due to scoliosis. My
          treatments with Dr. Ian Davis of spinal adjustments and neck traction
          have improved my condition tremendously! I appreciate the dedication
          of Dr. Ian Davis and his staff in helping me achieve better physical
          health!
        </Testimonial>
        <Testimonial patient="Daniel S., West Seattle">
          I feel better, I look better, I'm standing taller! Thank you Dr. Ian!
        </Testimonial>
        <Testimonial patient="Pavel S., Burien" right>
          I drive a lot and have to for a living. Dr. Ian Davis was very helpful
          in my recovery from being rear ended and I'm very thankful for his
          good work.
        </Testimonial>
      </section>
    </Layout>
  )
}

export default IndexPage
